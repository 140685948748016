import React, { useEffect } from "react";
import { useAppStore } from "@ideatives/core/state/StateWrapper";

import About from "../Components/About";
import Banner from "../Components/Banner";
import Blogs from "../Components/Blogs";
import Brands from "../Components/Brands";
import Courses from "../Components/Courses";
import Feature from "../Components/Feature";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import NewsLetter from "../Components/NewsLetter";
import Platform from "../Components/Platform";
import Team from "../Components/Team";
import Testimonials from "../Components/Testimonials";
import Topics from "../Components/Topics";



const Home = () => {
    const { user } = useAppStore()

    if (!user) {
        return <>...</>
    }

    return user.kind === "Anonymous" ? <Banner /> : <>

        <Header />
        <Feature />
        <About />
        {/* <Brands section_padding_bottom={"section-padding-bottom"} /> */}
        
        {/* <Courses /> */}
        {/* <Platform /> */}
        {/* <NewsLetter /> */}
        {/* <Topics /> */}
        {/* <Testimonials /> */}
        {/* <Team /> */}
        {/* <Blogs /> */}
        {/* <Footer /> */}
    </>;
};

export default Home;
