import React from "react";

const Feature = () => {
  return (
    <div className="feature-area bg-[url('../images/all-img/section-bg-1.png')] bg-cover bg-no-repeat bg-center section-padding">
      <div className="container">
        <div className="text-center">
          <br />
          <div className="bg-white shadow-box rounded-[8px]  p-10 group transition duration-150 hover:-translate-y-1">
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150">
              En lista de espera
            </h4>
            <div className="transition duration-150">
              <span className="shape-bg">Profe.club</span> se encuentra en modo privado,
              tu solicitud ha sido procesada y te notificaremos cuando esta sea aprobada.
            </div>
            <br />
            <div className="flex-none">
              <a href="#acelera" className="btn btn-primary w-full lg:w-auto">
                ¡Acelera el proceso!
              </a>
            </div>

          </div>

          <br /><br /><br />
          {/* <div className="mini-title">Core Features</div> */}
          <div className="column-title ">
            ¿Qué es <span className="shape-bg">Profe.club</span>?
          </div>
          {/* <div className="plain-text text-gray leading-[30px] pt-4">
            Conecta con otros educadores, automatiza tus clases, y mejora tu
            experiencia como maestro.
          </div> */}
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">

          <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8 text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <iconify-icon icon="material-symbols:checklist"></iconify-icon>
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Planificador
            </h4>
            <div className="transition duration-150 group-hover:text-white">
              Organiza tus clases, asigna tareas y evalúa el desempeño de tus estudiantes.
            </div>
          </div>

          <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <iconify-icon icon="material-symbols:group"></iconify-icon>
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Comunidad
            </h4>
            <div className="transition duration-150 group-hover:text-white">
              Conecta con otros maestros, comparte tus experiencias y mantente informado.
            </div>
          </div>

          <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <iconify-icon icon="material-symbols:contact-support"></iconify-icon>
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Asistente Virtual
            </h4>
            <div className="transition duration-150 group-hover:text-white">
              ¿Tienes dudas o necesitas ayuda? Utiliza el asistente virtual y mejorar tu experiencia.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
