import { React, useState } from "react";
import ManOne from "../assets/images/banner/profe.png";
import EmailValidator from 'email-validator';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Questionnaire } from "@ideatives/core/quizz/Questionnaire";
import { useAppStore, joinWaitList, getUserFromApi } from "@ideatives/core/state/StateWrapper";
import { Card } from "react-bootstrap";

const Banner = () => {
  const [emailOrTel, setEmailOrTel] = useState("")
  const [signingUp, setSigningUp] = useState(false)
  const {getUser} = useAppStore()
  const [message,setMessage] = useState("Enviando solicitud...")

  const isPhone = () => {
    const phoneRegex = /^\+?[0-9]*$/;
    return phoneRegex.test(emailOrTel) && emailOrTel.length > 0;
  };

  const isValidPhone = () => {

    const phoneRegex = /^\+?[0-9]{7,}$/;
    return phoneRegex.test(emailOrTel.replace(/\D/g, '')); // strip non-digits and check
  };

  const isValidEmail = () => {
    return EmailValidator.validate(emailOrTel)
  }

  const validContact = () => {
    return (!isPhone() && isValidEmail()) || (isPhone() && isValidPhone())
  }

  const signUp = () => {
    setSigningUp(true)
    joinWaitList(emailOrTel).then(data => {
      setMessage("Hemos recibido su solicitud, procesando...")
      setTimeout(getUser, 2000)
    })
  }


  return (
    <section
      className={`bg-[url('../images/banner/3.png')] bg-cover bg-no-repeat bg-center overflow-hidden banner`}
    >
      <div className="container relative flex">
        <div className="space-y-8 form">
          <h1>
            {/* Bienvenido a {" "} */}
            <span className=" text-secondary inline-block bg-[url('../images/banner/shape.svg')]  bg-no-repeat bg-bottom">
              Profe.club
            </span>{" "}
          </h1>
          <h3>Red Social y Asistente Pedagógico</h3>
          <div className=" plain-text text-gray leading-[30px]">
            Regístrate y descubre cómo conectarte con otros educadores, automatizar tus clases, y mejorar tu experiencia como maestro.
          </div>

          <div className="bg-white md:flex  rounded-md shadow-e1 items-center py-[10px] relative lg:pr-[10px] lg:pl-[38px] pl-4 pr-4">
            {!signingUp && <>
              <div className="flex-1 flex items-center lg:mb-0 mb-2">
                <div className="flex-none">
                  <span className=" ">
                    <FontAwesomeIcon size="xl" icon={isPhone() ? faWhatsapp : faEnvelopeOpen} />
                  </span>
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    placeholder="Correo ó Whatsapp"
                    className="border-none focus:ring-0"
                    value={emailOrTel}
                    onChange={(e) => {
                      setEmailOrTel(e.target.value.replace(" ", ""))
                    }}
                  />
                </div>
              </div>
              <div className="flex-none">
                <button
                  disabled={!validContact()}
                  onClick={signUp}
                  className="btn btn-primary w-full lg:w-auto">
                  ¡Únete ya!
                </button>
              </div>
            </>}

            {signingUp && <div className="center flex-1 items-center text-center">
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> {' '} &nbsp;&nbsp;{message}
            </div>}
          </div>

        </div>
        <div className="imge-box bottom-0  flex">
          <img src={ManOne} alt="manOne" />
        </div>
      </div>
    </section>
  );
};

export default Banner;
