import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Home from "./profe/Home";
import ErrorPage from "./Components/Pages/404Page";

// import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
