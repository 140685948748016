/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import lightImg from "../assets/images/svg/light.svg";
import aboutOne from "../assets/images/all-img/about1.png";
import targetImg from "../assets/images/svg/target.svg";
const About = () => {
  return (
    <div id="acelera" className="about-area section-padding">
      <div className="container ">
        <div className="text-center">
          <div className="mini-title">Toma Nota</div>
          <h4 className="column-title ">
            <span className="shape-bg">Acelera</span> {' '}
            tu solicitud

          </h4>
          <div>
            El proceso de aprobación es en orden de registro, sin embargo, podemos acelerar tu solicitud realizando los pasos siguientes.
          </div>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
          <div>

            <ul className=" list-item space-y-6 pt-8">

              <li className="flex">
                <div className="flex-none mr-6">
                  <div
                    className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8 text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
                  >
                    <iconify-icon icon="hugeicons:user-edit-01"></iconify-icon>
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="text-xl mb-1">Llena tu perfil</h4>
                  <div>
                    Completa tu perfil con información relevante, esto nos ayudará a conocerte mejor y acelerar tu proceso de aprobación.
                  </div>
                </div>
              </li>
              <li className="flex">
                <div className="flex-none mr-6">
                  
                <div
                    className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8 text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
                  >
                    <iconify-icon icon="mdi:invite"></iconify-icon>
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">Agrega contactos</h4>
                  <div>
                    Invita a tus colegas a unirse a Profe.club, mientras más contactos tengas, más rápido será tu proceso de aprobación.
                  </div>
                </div>
              </li>
            </ul>

          </div>
          <div>
            <ul className=" list-item space-y-6 pt-8">
              <li className="flex">
                <div className="flex-none mr-6">
                  
                <div
                    className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8 text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
                  >
                    <iconify-icon icon="mdi:talk"></iconify-icon>
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">Pide que te inviten</h4>
                  <div>
                    Pide a tus colegas que te inviten a Profe.club, esto acelerará tu proceso de aprobación.
                  </div>
                </div>
              </li>
              <li className="flex">
                <div className="flex-none mr-6">
                  
                <div
                    className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8 text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
                  >
                    <iconify-icon icon="mdi:envelope"></iconify-icon>
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="text-xl mb-1">Escríbenos</h4>
                  <div>
                    Envíanos un correo a <a href="mailto:activacion@profe.club?subject=Activa mi cuenta en Profe.club" className="text-primary">activacion@profe.club</a> y cuéntanos por qué necesitas acelerar tu proceso de aprobación.
                  </div>
                </div>
              </li>
            </ul>


          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
